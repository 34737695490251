const createMappingModelJSON = () => {
  return {
    headerMedium: {
      path: 'headerMedium',
    },
    descriptionMedium: {
      path: 'descriptionMedium',
    },
    headerShort: {
      path: 'headerShort',
    },
    descriptionShort: {
      path: 'descriptionShort',
    },
    headerLong: {
      path: 'headerLong',
    },
    descriptionLong: {
      path: 'descriptionLong',
    },
    legalLong: {
      path: 'legalLong',
    },
    legalShort: {
      path: 'legalShort',
    },
    caption: {
      path: 'caption',
    },
    captionUrl: {
      path: 'captionUrl',
    },
    callToActionText1: {
      path: 'callToActionText1',
    },
    callToActionText2: {
      path: 'callToActionText2',
    },
    destinationUrl1: {
      path: 'destinationUrl1',
    },
    destinationUrl2: {
      path: 'destinationUrl2',
    },
    image: {
      path: 'image',
    },
    logo: {
      path: 'logo',
    },
    eyebrowText: {
      path: 'eyebrowText',
    },
    metaData: {
      path: 'metadata',
    },
    responseTokens: {
      path: 'responseTokens',
    },
    mathLabel1: {
      path: 'mathLabel1',
    },
    mathLabel2: {
      path: 'mathLabel2',
    },
    mathValue: {
      path: 'mathValue',
    },
    mathLabel3: {
      path: 'mathLabel3',
    },
    mathTitle: {
      path: 'mathTitle',
    },
    mathType: {
      path: 'mathType',
    },
    mboxName: {
      path: 'mboxName',
    },
  };
};

export default createMappingModelJSON;
